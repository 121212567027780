<!-- <aside class="app-sidebar">
    <ul>
       
        <li><a routerLink="/user">User</a></li>
        <li><a (click)="detectRoute()" >Check In List</a></li>
        <li><a routerLink="/store">Store</a></li>
        <li><a routerLink="/blockROute">Block Route</a></li>
        <li><a routerLink="/route">Route Master</a></li>




      
    </ul>
</aside> -->

<!-- admin lte theme integration -->

<!-- <nav class="main-header navbar navbar-expand navbar-light custom-sidebar"> -->
  <nav class="main-header navbar navbar-expand sticky-top custom-sidebar">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" style="font-size:22px;" href="#" role="button"><i class="fas fa-bars" style="color:#399e8f;"></i></a>
      </li>
    </ul>
  
    <ul>
      <li>
        <div class="abs">
  
          <button mat-button (click)="logout()" matTooltip="Logout" class="tran">
            <mat-icon style="color:#399e8f;">logout</mat-icon>
          </button>
        </div>
      </li>
    </ul>
  
  </nav>
  
  <aside class="main-sidebar sidebar-light-blue elevation-4 custom-sidebar">
  
    <div class="logo_wraper">
      <div> Admin Panel </div>
      <!-- <img
            class="logo_sidebar"
            src="./assets/images/servivetLogo.svg"
            alt="Servivet Logo"
          /> -->
    </div>
    <div class="sidebar">
      <nav class="mt-7">
        <ul class="nav nav-pills nav-sidebar flex-column">
  
  
          <li class="nav-item" *ngIf="role==1||permission?.employee != 1 && permission?.employee !==undefined">
            <a [routerLink]="['/user']" class="nav-link" [class.active]="isRouteActive(['/user'])">
              <mat-icon class="rel-5">person</mat-icon>
              <span class="rels">Employee</span>
            </a>
           
          </li>
  
          <li class="nav-item" *ngIf="role==1||permission?.checkIn != 1 && permission?.checkIn !==undefined">
            <a (click)="detectRoute()" [ngClass]="{'active': isActive}" class="nav-link" >
              <mat-icon class="rel-5">fingerprint</mat-icon>
              <span class="rels">Check In List</span>
            </a>
          
          </li>
  
          <li class="nav-item" *ngIf="role==1|| (permission && permission?.store != 1 && permission?.store !== undefined)">
            <a [routerLink]="['/store']" class="nav-link" [class.active]="isRouteActive(['/store'])">
              <mat-icon class="rel-5">store</mat-icon>
  
              <span class="rels">Store</span>
            </a>
           
          </li>
  
          <li class="nav-item" *ngIf="role==1||(permission?.blockRoute != 1 && permission?.blockRoute !== undefined)">
            <a [routerLink]="['/blockROute']" class="nav-link" [class.active]="isRouteActive(['/blockROute'])">
              <mat-icon class="rel-5">place</mat-icon>
  
              <span class="rels">Block Master</span>
            </a>
          
          </li>
  
          <li class="nav-item" *ngIf="role==1||permission?.routeMaster != 1 && permission?.routeMaster !== undefined">
            <a [routerLink]="['/route']" class="nav-link" [class.active]="isRouteActive(['/route'])">
              <mat-icon class="rel-5">place</mat-icon>
  
              <span class="rels">Route Master</span>
            </a>
          
          </li>
  
          <li class="nav-item" routerLinkActive="active" *ngIf="role==1||(permission?.masterDocuments != 1 && permission?.masterDocuments !==undefined)">
            <a [routerLink]="['/documents']" class="nav-link" [class.active]="
                      isRouteActive(['/documents']) ">
              <mat-icon class="rel-5"> local_mall</mat-icon>
              <span class="rels">Master Documents</span>
  
            </a>
          
  
          </li>
  
          <li class="nav-item" routerLinkActive="active" *ngIf="role==1">
            <a [routerLink]="['/rights']" class="nav-link" [class.active]="
                      isRouteActive(['/rights']) ">
              <mat-icon class="rel-5"> security</mat-icon>
              <span class="rels">Permissions</span>
  
            </a>
          
  
          </li>

          <li class="nav-item">
            <a [routerLink]="['/reports']" class="nav-link" [class.active]="isRouteActive(['/reports'])">
              <mat-icon  class="rel-5">event</mat-icon>
  
              <span class="rels">Reports</span>
            </a>
          </li>

          <li class="nav-item">
            <a [routerLink]="['/settings']" class="nav-link" [class.active]="isRouteActive(['/settings'])">
              <mat-icon  class="rel-5">settings</mat-icon>
  
              <span class="rels">Settings</span>
            </a>
            <div class="divider"></div>
          </li>
  
  
  
  
  
  
  
        </ul>
      </nav>
    </div>
  
  
  </aside>
  
  
  <!-- jsdjdsfkjsjdsjdjsjjsjsjsjsjsjs -->